<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{$t('acc-settings.create.new-2')}}</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="create" class="form-horizontal">
          <div class="modal-body">
            <div class="form-body">
              <div class="form-group row">
                <label class="control-label col-md-6 text-left-m"
                  >System ID</label
                >
                <div class="col-md-6">
                  <input
                    v-model="form.system_id"
                    type="text"
                    required
                    class="form-control"
                    minlength="8"
                    maxlength="16"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label col-md-6 text-left-m"
                  >{{$t('generic-str.password')}}</label
                >
                <div class="col-md-6">
                  <input
                    v-model="form.password"
                    type="text"
                    required
                    class="form-control"
                    minlength="4"
                    maxlength="8"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label col-md-6 text-left-m"
                  >{{$t('options-div.lbl-replace-chars')}}</label
                >
                <div class="col-md-6">
                  <input type="checkbox" id="checkbox" v-model="form.normalize" style="margin-top:10px;">
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hide">
              {{$t('generic-str.cancel')}}
            </button>
            <button
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
              :disabled="isSending"
              type="submit"
              class="btn btn-primary"
            >
              {{$t('generic-str.create')}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import SmSService from '@/services/sms.service';
import Modal from '@/mixins/Modal';

export default {
  components: {
  },
  mixins: [Modal],
  props: ['id'],
  data() {
    return {
      form: {
        normalize: true,
        active: true,
        ip: '',
      },
      isSending: false,
    };
  },
  created() {},
  methods: {
    create() {
      this.isSending = true;
      SmSService.createSMPPUser(this.form).then(
        () => {
          this.$root.$emit('smpp.send');
          this.hide();
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: this.$t('acc-settings.create.success'),
            type: 'success',
          });
          this.isSending = false;
        },
        (error) => {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
            content: error.response.data.message,
            type: 'danger',
          });
          this.isSending = false;
        },
      );
    },
  },
};
</script>

<style lang="scss">
.vti__dropdown-list {
  z-index: 1000 !important;
}
.vue-tel-input {
  border-color: #dfe7f3 !important;
  border: 1px solid rgba(120, 141, 180, 0.3) !important;
}
</style>
