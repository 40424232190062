<template>
  <div class="content">
    <PageHeader :title="this.$t('sms.smpp-users.header')" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 to-left">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <button
                        v-modal="{ target: 'smpp-m' }"
                        class="btn btn-success emit-j-event"
                      >
                        <i class="icon dripicons-plus"></i
                        ><span class="hide-m">{{$t('generic-str.lbl-new-user')}}</span>
                      </button>
                      <button
                        class="btn btn-danger btn-remove border-radius-right"
                        @click="deleteAll"
                        :class="{ disabled: selectedUsers.length == 0 }"
                      >
                        <i class="icon dripicons-trash color-white"></i
                        ><span class="hide-m">{{$t('generic-str.delete')}}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body block-el p-0">
              <div class="responsive-table">
                <div
                  v-if="!users.length && fetched"
                  class="text-center table-placeholder"
                >
                  <br>
                  <i class="fas fa-cog font-size-80"></i>
                  <h5 class="card-title m-t-20">Nenhuma conta SMPP cadastrada</h5>
                </div>
                <table v-if="fetched" class="table">
                  <thead v-if="users.length" class="bg-light">
                    <tr>
                      <th class="w-60" scope="col">
                        <div class="custom-control custom-checkbox">
                          <input
                            v-model="selectAllUsers"
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheck1"
                          />
                          <label
                            class="custom-control-label"
                            for="customCheck1"
                          ></label>
                        </div>
                      </th>
                      <th scope="col">SYSTEM ID</th>
                      <th scope="col">C. Especiais</th>
                      <th scope="col">Status</th>
                      <th scope="col">TX</th>
                      <th scope="col">RX</th>
                      <th scope="col">TRX</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(user, i) in users" :key="user.id">
                      <td data-label>
                        <div class="custom-control custom-checkbox">
                          <input
                            v-model="selectedUsers"
                            type="checkbox"
                            class="custom-control-input"
                            :id="'user' + i"
                            :value="user"
                          />
                          <label
                            class="custom-control-label"
                            :for="'user' + i"
                          ></label>
                        </div>
                      </td>
                      <td data-label="SYSTEM ID">
                        {{ user.system_id }}
                      </td>
                      <td data-label="C. Especiais">
                        <i v-if="!user.normalize" class="fas fa-check"></i>
                        <i v-else class="fas fa-times"></i>
                      </td>
                      <td data-label="Status">
                        <span class="badge badge-info" v-if="user.active">{{$t('generic-str.status.lbl-active')}}</span>
                        <span class="badge badge-danger" v-if="!user.active">{{$t('generic-str.status.lbl-inactive')}}</span>
                      </td>
                      <td data-label="TX">{{ user.tx ? user.tx : 'Não Conectado' }}</td>
                      <td data-label="RX">{{ user.rx ? user.rx : 'Não Conectado' }}</td>
                      <td data-label="TRX">{{ user.trx ? user.trx : 'Não Conectado' }}</td>
                      <td data-label>
                        <div class="dropdown">
                          <a
                            role="button"
                            class="dropdown-action"
                            :to="'#menu-dropdown-' + i"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="icon dripicons-gear text-success"></i>
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            :id="'menu-dropdown-' + i"
                            x-placement="bottom-end"
                            style
                          >
                            <a
                              class="dropdown-item emit-j-even"
                              v-modal="{
                                target: 'create-smpp-m',
                                data: user,
                              }"
                              >{{$t('generic-str.edit')}}</a
                            >
                            <a class="dropdown-item" @click="remove(user.id)"
                              >{{$t('generic-str.remove')}}</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  v-else
                  class="qt-block-ui relative"
                  style="padding: 120px"
                />
                <br />
                <pagination :lastPage="pages" @change="fetch" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <create-user-modal
      id="create-user"
      @sent="fetch(form.page)"
    ></create-user-modal>
    <create-smpp-modal id="smpp-m" @sent="fetch(form.page)"></create-smpp-modal>
    <smpp-modal id="create-smpp-m" @sent="fetch(form.page)"></smpp-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import SmsService from '@/services/sms.service';
import SmppModal from '@/components/smpp/SmppModal.vue';
import CreateSmppModal from '@/components/smpp/CreateSmppModal.vue';
import Pagination from '@/components/Pagination.vue';
import Swal from 'sweetalert2';

export default {
  name: 'ListUser',
  components: {
    PageHeader,
    Pagination,
    SmppModal,
    CreateSmppModal,
  },
  data() {
    return {
      fetched: false,
      users: [],
      form: {
        name: '',
        page: 1,
      },
      pages: 1,
      selectedUsers: [],
    };
  },
  computed: {
    selectAllUsers: {
      get() {
        if (this.users) {
          return this.selectedUsers.length === this.users.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.users.forEach((user) => {
            selected.push(user);
          });
        }

        this.selectedUsers = selected;
      },
    },
  },
  created() {
    this.fetch();
  },
  mounted() {
    this.$root.$on('smpp.send', this.fetch);
  },
  methods: {
    fetch() {
      this.fetched = false;
      SmsService.getUsers(this.form).then(
        (response) => {
          this.fetched = true;
          this.users = response;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    remove(id) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text:
          'O usuário será excluído permanentemente. Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
      }).then((result) => {
        if (result.isConfirmed) {
          SmsService.deleteUser(id).then(
            () => {
              this.fetch(this.form.page);
              Swal.fire(`${this.$t('generic-str.deleted')}!`, 'Seu usuário foi excluído.', 'success');
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
    deleteAll() {
      Swal.fire({
        title: 'Remover usuários',
        text: 'Tem certeza que gostaria de remover os usuários?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          const toDelete = [];
          this.selectedUsers.forEach((item) => {
            toDelete.push(item.id);
          });
          SmsService.deleteUsers({ ids: toDelete }).then(
            () => {
              this.fetch(this.form.page);
              Swal.fire(
                `${this.$t('generic-str.deleted')}!`,
                'Seus usuários foram excluídos.',
                'success',
              );
              this.selectedUsers = [];
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.response.data.message,
                type: 'danger',
              });
            },
          );
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.custom-control-label {
  &::after,
  &::before {
    top: -0.5rem;
  }
}
.btn i {
  line-height: 1;
  color: inherit;
}
.btn {
  margin-bottom: 5px;
}
</style>
